import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>) {
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    // TODO: try to change on native trackView with custom params
    GTM.trackView = function (screenName, path) {}

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    const getProduct = (item) => {
      const attributes = store.getters['attribute/attributeListByCode']
      // const category = store.getters['category-next/getCurrentCategory']
      const breadcrumbs = store.getters['breadcrumbs/getBreadcrumbsRoutes']

      const categoryPaths = breadcrumbs.map(cat => cat.name).join('~')
      const categoryIds = breadcrumbs.map(cat => cat.id).join('~')

      const { name, sku, parentSku, regular_price, special_price_incl_tax, id, qty: quantity, brand, sex, image, parent_id } = item
      let current_price = special_price_incl_tax !== 0 ? special_price_incl_tax : regular_price
      current_price = Math.abs(parseFloat(current_price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '')
      let currentBrand; let currentGender = ''
      if (attributes.brand) {
        attributes.brand.options.forEach(option => {
          if (option.value === String(brand)) {
            currentBrand = option.label
          }
          // else {
          //   currentBrand = brand
          // }
        })
      } else {
        currentBrand = brand
      }
      if (attributes.sex) {
        attributes.sex.options.forEach(option => {
          if (option.value === String(sex)) {
            currentGender = option.label
          }
        })
      } else {
        currentGender = sex
      }
      let product = {
        name,
        childSku: sku,
        fbId: parent_id,
        id: parentSku,
        price: current_price,
        brand: currentBrand,
        gender: currentGender,
        product_category_ids: categoryIds,
        product_category_names: categoryPaths,
        product_images: 'https://assets.artykulygrzewcze.pl/img/150/150/resize/product' + image
      }
      if (quantity) {
        product['quantity'] = quantity
      }

      // if (category_path) {
      //   product['category'] = category_path
      // }

      return product
    }

    // const getBanner = (item) => {
    //   const { name } = item
    //   let banner = {
    //     id: name,
    //     position: 'promotion_slider'
    //   }
    //   return banner
    // }

    const checkoutObj = (step, products) => {
      let cartProducts = []
      let productIds = []
      let total = 0
      products.map(product => {
        productIds.push(product.id)
        total += product.price
      })
      for (let product of products) {
        cartProducts.push(getProduct(product))
      }
      return {
        event: 'checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'checkout',
        ecomm_prodid: productIds,
        ecomm_pagetype: 'cart',
        ecomm_totalvalue: total,
        ecommerce: {
          checkout: {
            actionField: {
              step: step
            },
            products: cartProducts
          }
        }
      }
    }

    store.subscribe(({ type, payload }, state) => {
      const target = (window as any);
      let dataLayer = (target.dataLayer = target.dataLayer || [])
      // set type of page
      const setCurrentPage = () => {
        let isHomePage = payload.to.name === 'home'
        let isProductPage = !!payload.to.params.parentSku
        let isCheckoutPage = payload.to.name === 'checkout' || payload.to.name === 'en-checkout'
        let isStaticPage = payload.to.name === 'cms-page' || payload.to.name === 'en-cms-page'
        let isCategoryPage = payload.to.name.includes('urldispatcher') && !isProductPage
        let isAccountPage = payload.to.name === 'my-account' || payload.to.name === 'en-my-account'
        let isBrandPage = payload.to.params.slug === 'brands' || payload.to.params.slug === 'marki'
        if (isBrandPage) {
          return 'brand'
        } else if (isHomePage) {
          return 'homepage'
        } else if (isProductPage) {
          return 'product'
        } else if (isCheckoutPage) {
          return 'checkout'
        } else if (isStaticPage) {
          return 'static-page'
        } else if (isAccountPage) {
          return 'my-account'
        } else if (isCategoryPage) {
          return 'category'
        } else {
          return 'unknown'
        }
      }
      const getPageTitle = () => {
        let currentCategory = store.getters['category-next/getCurrentCategory']
        let pageTitle = ''
        switch (setCurrentPage()) {
          case 'product':
            pageTitle = state.product.current.meta_title || state.product.current.name
            break
          case 'static-page':
            pageTitle = state.cmsPage.current ? state.cmsPage.current.title : ''
            break
          case 'category':
            pageTitle = Object.keys(currentCategory).length
              ? currentCategory.meta_title || currentCategory.name
              : ''
            break
          default:
            pageTitle = 'Grzejniki Dekoracyjne, Pokojowe, Łazienkowe - Duży Wybór!'
            break
        }
        if (pageTitle.length < 45) return pageTitle.concat(' - Sklep')
        if (pageTitle.length > 60) return pageTitle.slice(0, 60)
        return pageTitle
      }
      // Changing route
      if (type === 'ui/routeChanged' || (type === 'route/ROUTE_CHANGED' && payload.to.name === 'checkout' && payload.to.hash)) {
        GTM.trackEvent({
          event: 'route-changed',
          'content-type': setCurrentPage(),
          'content-title': getPageTitle(),
          'content-name': payload.to.path,
          'content-view-name': payload.to.name,
          'visitor-login-state': 'logged-out', // TODO: po dodaniu dzialania logowania
          language: 'pl', // TODO
          uiGender: null, // TODO
          uiCity: null, // TODO
          uiUser: null, // TODO
          transactionShippingMethid: null // TODO
        })
        GTM.trackEvent({
          event: 'content-view',
          'content-name': payload.to.path,
          'content-type': setCurrentPage()
        })
        // Single product page
        if (payload.to.params.parentSku) {
          const currentProduct = store.getters['product/getCurrentProduct']
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Details',
            ecommerce: {
              detail: {
                actionField: {
                  list: 'product detail page'
                },
                products: [getProduct(currentProduct)]
              }
            }
          });
        }

        if (payload.to.name === 'checkout') {
          // Start checkout
          if (!payload.to.hash) {
            GTM.trackEvent(checkoutObj(2, state.cart.cartItems));
          } else if (payload.to.hash === '#shipping') {
            GTM.trackEvent(checkoutObj(3, state.cart.cartItems));
          } else if (payload.to.hash === '#payment') {
            GTM.trackEvent(checkoutObj(4, state.cart.cartItems));
          } else if (payload.to.hash === '#orderReview') {
            GTM.trackEvent(checkoutObj(5, state.cart.cartItems));
          }
        }
      }

      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Add to Cart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Open cart
      if (type === 'ui/setMicrocart') {
        if (payload) {
          GTM.trackEvent(checkoutObj(1, state.cart.cartItems));
        }
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          eventCategory: 'Ecommerce',
          eventAction: 'Remove from Cart',
          ecommerce: {
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Measuring Views of Product Details
      /*
      if (type === 'product/product/SET_CURRENT') {
        GTM.trackEvent({
          ecommerce: {
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Details',
            detail: {
              'actionField': { 'product detail page': '' }, // 'detail' actions have an optional list property.
              'products': [getProduct(payload)]
            }
          }
        });
      }
      */

      // Measuring Views of Promotions
      if (type === 'banners/SET_MAIN_BANNER') {
        if (payload) {
          GTM.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Promotion Impressions',
            ecommerce: {
              promoView: {
                promotions: [
                  {
                    id: payload.id,
                    name: payload.name,
                    position: payload.location,
                    creative: payload.size
                  }
                ]
              }
            }
          });
        }
      }

      // Added in theme templates:
      // ProductTile:
      // <router-link class="block no-underline product-link" :to="productLink" @click.native="productClick" data-testid="productLink">

      // ProductTileRelated

      // ProductListing:
      // <product-tile :product="product" :position="key" list="catalog"/>

      // Added in banner module:
      // Banner
      // BannerBlock
      // SmallBanner
    })
  }
}
