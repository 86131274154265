import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import MagentoWishlistState from '../types/MagentoWishlistState'

const getters: GetterTree<MagentoWishlistState, RootState> = {
  getItemId: state => product => {
    return state.itemIds[product.parentSku]
  },
  isOnWishlist: state => product =>
    state.items.some(p => p.parentSku === product.parentSku),
  isWishlistLoaded: state => state.loaded,
  loadStatus: state => state.loading,
  getWishlistItemsCount: state => state.items.length
}

export default getters
