import * as types from '@vue-storefront/core/modules/recently-viewed/store/mutation-types'
import { isServer } from '@vue-storefront/core/helpers'
import fetch from 'isomorphic-fetch'
import config from 'config'

export async function plugin (mutation, state) {
  const type = mutation.type

  if (!isServer && type.startsWith(types.SN_RECENTLY_VIEWED + '/' + types.RECENTLY_VIEWED_ADD_ITEM)) {
    const url = config.api.url + config.productview.endpoint + mutation.payload.product.parentSku
    fetch(url)
  }
}
