import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { RewriteFrames } from '@sentry/integrations'
import config from 'config'
import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'

const SentryIntegrationStore = {}

export const KEY = 'sentry-integration'

export const SentryIntegrationModule: StorefrontModule = function ({ store }) {
  store.registerModule(KEY, SentryIntegrationStore)

  config.sentry.enabled && Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true
      }),
      new RewriteFrames()
    ],
    tracesSampleRate: 1
  })
}
