import * as types from './mutation-types'
import config from 'config'
import { Module } from 'vuex'
import { EdroneState } from '../types/edroneState'
import { cacheStorage } from '..'

export const module: Module<EdroneState, any> = {
  namespaced: true,
  state: {
    isSubscribed: null,
    email: null
  },
  mutations: {
    [types.NEWSLETTER_SUBSCRIBE] (state) {
      state.isSubscribed = true
    },
    [types.NEWSLETTER_UNSUBSCRIBE] (state) {
      state.isSubscribed = false
    },
    [types.SET_EMAIL] (state, payload) {
      state.email = payload
    }
  },
  actions: {
    subscribe ({ commit, state }, { email, tag }) {
      if (!state.isSubscribed) {
        // return new Promise((resolve, reject) => {
        //   fetch(config.edrone.endpoint, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     mode: 'cors',
        //     body: JSON.stringify({ email })
        //   }).then(res => {
        //     commit(types.NEWSLETTER_SUBSCRIBE)
        //     commit(types.SET_EMAIL, email)
        //     cacheStorage.setItem('email', email)
        //     resolve()
        //   }).catch(err => {
        //     reject(err)
        //   })
        // })
        commit(types.NEWSLETTER_SUBSCRIBE);
        commit(types.SET_EMAIL, email);
        // cacheStorage.setItem('email', email)
        // (<any>window)._edrone.customer_tags = 'From footer';
        // (<any>window)._edrone.email = email;
        // (<any>window)._edrone.first_name = '';
        // (<any>window)._edrone.action_type = 'subscribe';
        // (<any>window)._edrone.init();
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: 'subscribe',
            eventAction: 'subscribe',
            data: {
              customer_tags: tag,
              email: email,
              first_name: '',
              action_type: 'subscribe'
            }
          })
        }
        return true
      } else {
        console.log('already subscribed')
        return false
      }
    },
    unsubscribe ({ commit, state }, email): Promise<Response> {
      if (!state.isSubscribed) {
        return new Promise((resolve, reject) => {
          fetch(config.edrone.endpoint, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({ email })
          }).then(res => {
            commit(types.NEWSLETTER_UNSUBSCRIBE)
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      }
    }
  }
}
