export const uiStore = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    addToCart: false,
    searchpanel: false,
    availableVariation: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    blikLoader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    isSaleFilterActive: false,
    isNewFilterActive: false,
    diliveryFilter: '',
    rangeForDilivery: {},
    widthFilter: '',
    rangeForWidth: [],
    heightFilter: '',
    rangeForHeight: [],
    isColorBlocked: true,
    isAttributesUnblocked: false,
    chosenColor: {},
    isEcommerceFilterActive: false,
    isQueryFilterActive: false,
    imageResultResponse: null,
    checkoutTypes: ['Osoba prywatna', 'Firma'],
    selectedCheckoutType: 'Osoba prywatna',
    isNipDataResponse: '',
    currentTab: true,
    ceneoConsent: false
  },
  getters: {
    getCeneoConsent: (state) => state.ceneoConsent,
    getDiliveryFilter: (state) => state.diliveryFilter
  },
  mutations: {
    routeChanged () {
    },
    toogleCeneoConsent (state) {
      state.ceneoConsent = !state.ceneoConsent
    },
    changeImageResultResponse (state, action) {
      state.imageResultResponse = action
    },
    setNipDataResponse (state, action) {
      state.isNipDataResponse = action
    },
    setCheckoutType (state, action) {
      state.selectedCheckoutType = action
    },
    setColorBlocked (state, action) {
      state.isColorBlocked = action === true
    },
    setAttributesUnblocked (state, action) {
      state.isAttributesUnblocked = action === true
    },
    setChosenColor (state, payload) {
      state.chosenColor = payload
    },
    setAvailableVariation (state, action) {
      state.availableVariation = action === true
      state.overlay = action === true
    },
    setAddToCart (state, action) {
      state.addToCart = action === true
      state.overlay = action === true
    },
    setSaleFilter (state, action) {
      state.isSaleFilterActive = action === true
    },
    setNewFilter (state, action) {
      state.isNewFilterActive = action === true
    },
    setEcommerceFilter (state, action) {
      state.isEcommerceFilterActive = action === true
    },
    setQueryFilter (state, action) {
      state.isQueryFilterActive = action === true
    },
    setDeliveryFilter (state, payload) {
      state.diliveryFilter = payload
    },
    setWidthFilter (state, payload) {
      state.widthFilter = payload
    },
    setWidthRange (state, payload) {
      state.rangeForWidth = payload
    },
    setHeightFilter (state, payload) {
      state.heightFilter = payload
    },
    setHeightRange (state, payload) {
      state.rangeForHeight = payload
    },
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      state.wishlist = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setBlikLoader (state, action) {
      state.blikLoader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    },
    setCurrentTab (state, action) {
      state.currentTab = action
    }
  },
  actions: {
    toogleCeneoConsent ({ commit }) {
      commit('toogleCeneoConsent')
    },
    changeCurrentTab ({ commit }, data) {
      commit('setCurrentTab', data)
    },
    changeNipDataResponse ({ commit }, data) {
      commit('setNipDataResponse', data)
    },
    changeCheckoutType ({ commit }, data) {
      commit('setCheckoutType', data)
    },
    toggleAvailableVariation ({ commit, state }) {
      commit('setAvailableVariation', !state.availableVariation)
    },
    toggleAddToCart ({ commit, state }) {
      commit('setAddToCart', !state.addToCart)
    },
    toggleSaleFilter ({ commit, state }) {
      commit('setSaleFilter', !state.isSaleFilterActive)
    },
    toggleNewFilter ({ commit, state }) {
      commit('setNewFilter', !state.isNewFilterActive)
    },
    toggleEcommerceFilter ({ commit, state }) {
      commit('setEcommerceFilter', !state.isEcommerceFilterActive)
    },
    toggleDeliveryFilter ({ commit }, payload) {
      commit('setDeliveryFilter', payload)
    },
    toggleWidthFilter ({ commit }, payload) {
      commit('setWidthFilter', payload.label)
      commit('setWidthRange', payload.arrId)
    },
    resetWidthFilter ({ commit }, payload = '') {
      commit('setWidthFilter', payload)
      commit('setWidthRange', [])
    },
    toggleHeightFilter ({ commit }, payload) {
      commit('setHeightFilter', payload.label)
      commit('setHeightRange', payload.arrId)
    },
    resetHeightFilter ({ commit }, payload = '') {
      commit('setHeightFilter', payload)
      commit('setHeightRange', [])
    },
    resetDeliveryFilter ({ commit }, payload = '') {
      commit('setDeliveryFilter', payload)
    },
    resetSaleFilter ({ commit, state }) {
      commit('setSaleFilter', false)
    },
    resetNewFilter ({ commit, state }) {
      commit('setNewFilter', false)
    },
    resetEcommerceFilter ({ commit, state }) {
      commit('setEcommerceFilter', false)
    },
    toggleQueryFilter ({ commit, state }) {
      commit('setQueryFilter', !state.isQueryFilterActive)
    },
    resetQueryFilter ({ commit, state }) {
      commit('setQueryFilter', false)
    },
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    setColorBlocked ({ commit, state }) {
      commit('setColorBlocked', true)
    },
    unsetColorBlocked ({ commit, state }) {
      commit('setColorBlocked', false)
    },
    setChosenColor ({ commit }, payload) {
      commit('setChosenColor', payload)
    },
    unsetChosenColor ({ commit }, payload = {}) {
      commit('setChosenColor', payload)
    },
    setAttributesUnblocked ({ commit, state }) {
      commit('setAttributesUnblocked', true)
    },
    unsetAttributesUnblocked ({ commit, state }) {
      commit('setAttributesUnblocked', false)
    }
  }
}
