export const attributesStore = {
  namespaced: true,
  state: {
    mappedAttributes: {}
  },
  mutations: {
    setAttributesMap (state, { type, attributeOptions }) {
      state.mappedAttributes[type] = attributeOptions
    }
  },
  actions: {
    callAttributesMapSetter ({ commit }, payload) {
      commit('setAttributesMap', {
        type: payload.type,
        attributeOptions: payload.attributeOptions
      })
    }
  }
}
