const Home = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home.vue')
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue')
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue')
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue')
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue')
const CmsPage = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/CmsPage.vue')
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue')
const Compare = () => import(/* webpackChunkName: "vsf-compare" */ 'theme/pages/Compare.vue')
const MyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount.vue')
const ThankYouPage = () => import(/* webpackChunkName: "vsf-typ" */ 'theme/pages/ThankYouPage.vue')
const PostsIndex = () => import(/* webpackChunkName: "vsf-wp-posts-index" */ 'theme/pages/PostsPageIndex.vue')
const PostsShow = () => import(/* webpackChunkName: "vsf-wp-posts-show" */ 'theme/pages/PostsPageShow.vue')
const CategoriesIndex = () => import(/* webpackChunkName: "vsf-wp-categories-index" */ 'theme/pages/CategoriesPageIndex.vue')
const CategoriesShow = () => import(/* webpackChunkName: "vsf-wp-categories-show" */ 'theme/pages/CategoriesPageShow.vue')
const ResetPassword = () => import(/* webpackChunkName: "vsf-reset-password" */ 'theme/pages/ResetPassword.vue');
const Placepozniej = () => import(/* webpackChunkName: "vsf-placepozniej" */ 'theme/pages/Placepozniej.vue')

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html', meta: { gtm: 'Mainsite', pageType: 'home', title: 'Grzejniki Dekoracyjne, Pokojowe, Łazienkowe - Duży Wybór!', description: 'Sklep internetowy AG oferuje grzejniki dekoracyjne do pokoju, łazienki i wiele innych. Grzejniki dekoracyjne żeliwne, retro i aluminiowe. Tel. 223974392. Zadzwoń!' } },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'my-account', path: '/my-account', component: MyAccount, meta: { requiresAuth: true } },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: MyAccount, props: { activeBlock: 'MyShippingDetails' }, meta: { requiresAuth: true } },
  { name: 'my-newsletter', path: '/my-account/newsletter', component: MyAccount, props: { activeBlock: 'MyNewsletter' }, meta: { requiresAuth: true } },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: { activeBlock: 'MyOrders' }, meta: { requiresAuth: true } },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' }, meta: { requiresAuth: true } },
  { name: 'my-recently-viewed', path: '/my-account/recently-viewed', component: MyAccount, props: { activeBlock: 'MyRecentlyViewed' }, meta: { requiresAuth: true } },
  { name: 'compare', path: '/compare', component: Compare, props: { title: 'Porównanie towarów' } },
  { name: 'error', path: '/error', component: ErrorPage },
  { name: 'thank-you-page', path: '/thank-you-page', component: ThankYouPage, props: (route) => ({ orderIncrementId: route.query.order_increment_id, error: route.query.error }) },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'cms-page', path: '/i/:slug', component: CmsPage },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'posts-index', path: '/porady', component: PostsIndex, props: { page: 'porady', title: 'Advices' } },
  { name: 'posts-show', path: '/porady/:slug', component: PostsShow, props: { page: 'porady', title: 'View Advices' } },
  { name: 'categories-index', path: '/categories', component: CategoriesIndex, props: { page: 'categories', title: 'View Categories' } },
  { name: 'categories-show', path: '/categories/:slug', component: CategoriesShow, props: { page: 'category', title: 'View Category' } },
  { name: 'create-password', path: '/create-password', component: ResetPassword },
  { name: 'placepozniej', path: '/placepozniej', component: Placepozniej },
  { name: 'page-not-found', path: '*', component: PageNotFound }
]

export default routes
