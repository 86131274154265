import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'

export const homepageStore = {
  namespaced: true,
  state: {
    new_collection: [],
    bestsellers: [],
    exclusive_collections: []
  },
  actions: {
    async fetchNewCollection ({ commit, dispatch }) {
      const newProductsQuery = prepareQuery({ queryConfig: 'newProducts' })

      const newProductsResult = await dispatch('product/list', {
        query: {
          'query': {
            'bool': {
              'must': [
                {
                  'exists': {
                    'field': 'news_to_date'
                  }
                },
                {
                  'match_phrase': {
                    'stock.is_in_stock': {
                      'query': true
                    }
                  }
                },
                {
                  'terms': {
                    'visibility': [2, 3, 4]
                  }
                },
                {
                  'terms': {
                    'status': [0, 1]
                  }
                }
              ],
              'must_not': [{
                'match_phrase': {
                  'image': {
                    'query': 'no_selection'
                  }
                }
              }]
            }
          }
        },
        size: 8,
        sort: 'id:desc'
      }, { root: true })
      const configuredProducts = await dispatch(
        'category-next/configureProducts',
        { products: newProductsResult.items
        }, { root: true })
      commit('SET_NEW_COLLECTION', configuredProducts)
    },
    async loadBestsellers ({ commit, dispatch }) {
      const response = await dispatch('product/list', {
        query: prepareQuery({ filters: [{ 'key': 'bestseller', 'value': { 'in': 1 } }], queryConfig: '' }),
        size: 8,
        sort: 'id:desc'
      }, { root: true })

      commit('SET_BESTSELLERS', response.items)
    },
    async loadExclusiveCollections ({ commit, dispatch }) {
      const response = await dispatch('product/list', {
        query: prepareQuery({ filters: [{ 'key': 'exclusive_collections', 'value': { 'in': 1 } }], queryConfig: '' }),
        size: 8,
        sort: 'id:desc'
      }, { root: true })

      commit('SET_EXCLUSIVE_COLLECTIONS', response.items)
    }
  },
  mutations: {
    SET_NEW_COLLECTION (state, products) {
      state.new_collection = products || []
    },
    SET_BESTSELLERS (state, bestsellers) {
      state.bestsellers = bestsellers
    },
    SET_EXCLUSIVE_COLLECTIONS (state, exclusives) {
      state.exclusive_collections = exclusives
    }
  },
  getters: {
    getEverythingNewCollection (state) {
      return state.new_collection
    },
    getBestsellers (state) {
      return state.bestsellers
    },
    getExclusiveCollection (state) {
      return state.exclusive_collections
    }
  }
}
